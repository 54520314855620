import Vue from 'vue'
import $ from 'jquery'
import Vuex from 'vuex'
import router from '@/router'
import imgError from '@/assets/cliente/img/error.png'
import Swal from 'sweetalert2'

Vue.use(Vuex)

const store = {
   state: {
      // Tiago //////////////////////////////////////////////////////////////////
      // clientId : "1196797408781279342",
		// redirectUri : encodeURIComponent("http://10.1.1.218:8080/"),
      // urlRest: 'http://10.1.1.237:8080/WorldFivem-0.0.1/',
      // urlRestExterna: 'http://0.0.0.0:8085/WorldFivem-0.0.1/',
      ///////////////////////////////////////////////////////////////////////////

      // Producao //////////////////////////////////////////////////////////////////
      clientId : "1296825817334022264",
      redirectUri : encodeURIComponent("https://worldfivem.com/"),
      urlRest: 'https://redepaulista.com.br:8444/WorldFivem/',
      urlRestExterna: 'https://redepaulista.com.br:8444/WorldFivem/',
      ///////////////////////////////////////////////////////////////////////////
      
      isRouteAdmin: false,
      isCarregando: false,
      isLogado: false,
      tokenSessao: null,
      tokenType: null,
      dadosUsuario: {},
      clienteData: {'destaques': []},
      carrinho: localStorage.carrinho == null ? {'itens': [], 'valorTotal': 0, 'toggle': false} : JSON.parse(localStorage.carrinho),
      pesquisa: {'tag': 'Todos', 'valor': null, 'filtro': 'Relevância', 'resultado': [], 'historico': null},
      routerHistory: [],
      firstRoute: null,
      listaPacotes: [],
      listaCursos: [],
      listaScripts: [],
      listaDonosScript: []
   },
   mutations: {
      saveHistory : (state, route) => {
         if (state.routerHistory.length == 0) {
            state.firstRoute = route;
         }

         state.routerHistory.push(route)
         window.scrollTo(0, 0)
      },
      isRouteAdmin : (state, isAdmin) => {
         state.isRouteAdmin = isAdmin

         if (isAdmin) {
            if (localStorage.theme != null) {
               $("html").addClass(localStorage.theme)
            } else {
               localStorage.theme = 'danger-theme'
               $("html").addClass('danger-theme')
            }
         }
      },
      login : (state, credenciais) => {
         let myDate = new Date(Date.now()).toLocaleString().split(',')[0];
         var fragment = new URLSearchParams(window.location.hash.slice(1));
         var [accessToken, tokenType] = [fragment.get('access_token'), fragment.get('token_type')];
         var obterDiscord = false;
         state.isCarregando = true;

         if (localStorage.accessToken == undefined || localStorage.tokenType == undefined || localStorage.ultimoLogin == undefined) {
            if (accessToken != null && tokenType != null && myDate != null) {
               localStorage.accessToken = accessToken;
               localStorage.tokenType = tokenType;
               localStorage.ultimoLogin = myDate;
               obterDiscord = true;
               
            } else {
               state.isLogado = false;
            }
         } else {
            accessToken = localStorage.accessToken;
            tokenType = localStorage.tokenType;

            if (localStorage.ultimoLogin == myDate) {
               obterDiscord = true;
            } else {
               store.mutations.deslogar(state)
            }
         }

         if (obterDiscord) {
            fetch('https://discord.com/api/users/@me', {
               headers: {
                  authorization: `${tokenType} ${accessToken}`
               }
            }).then(result => result.json()).then(response => {
               localStorage.accessToken = accessToken
               localStorage.tokenType = tokenType
               localStorage.ultimoLogin = myDate

               Vue.prototype.$axios({
                  method: 'get',
                  url: state.urlRest +'login',
                  params: {
                     idDiscord: response.id,
                     username: response.username,
                     email: response.email,
                     tokenType: tokenType,
                     accessToken: accessToken
                  }
               }).then(function (response) {
                  state.tokenSessao = response.data.tokenSessao;
                  state.isLogado = true;
                  state.dadosUsuario = response.data;

                  if (!credenciais.redirect) {
                     state.isRouteAdmin = true;
                     router.push('/admInicio').catch(function(){})
                  } else if (state.firstRoute.path == '/') {
                     router.push('/').catch(function(){})
                  }
               }).catch(function (error) {
                  if (state.firstRoute.path == '/') {
                     router.push('/').catch(function(){})
                  }

                  if (error.response != undefined) {
                     Vue.prototype.$toast.fire({
                        icon: 'error',
                        title: 'Erro: ' + error.response.status
                     })
                  } else {
                     Vue.prototype.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  state.isCarregando = false;
               });
            }).catch(function () {
               store.mutations.deslogar(state)
            });
         } else {
            if (state.firstRoute.path == '/') {
               router.push('/').catch(function(){})
            }
            
            state.isCarregando = false;
         }
      },
      saveData : (state, data) => {
         state.clienteData.destaques = data;
      },
      deslogar : state => {
         state.dadosUsuario = {}
         state.tokenSessao = null
         state.tokenType = null
         state.isLogado = false
         
         localStorage.removeItem('accessToken')
         localStorage.removeItem('tokenType')
         localStorage.removeItem('ultimoLogin')
         
         router.push('/')
      },
      setPacotesGratuitos : state => {
         state.isCarregando = true;

         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'servicos/setPacoteGratuito',
            headers: {
					'Content-Type': 'application/json'
            },
				data: state.carrinho.itens
            
         }).then(function () {
            store.mutations.limparCarrinho(state)
            store.mutations.toggleCarrinho(state, false)

            Swal.fire({
               icon: 'success',
               title: 'Pedido realizado!'
            });
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  store.mutations.deslogar(state)

                  Vue.prototype.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            state.isCarregando = false;
         })
      },
      alternarTelaCarregamento : (state, isCarregando) => {
         state.isCarregando = isCarregando;
      },
      toggleCarrinho : (state, toggle) => {
         state.carrinho.toggle = toggle

         if (toggle) {
            $(".cart__toggle").addClass("cart__toggle-open");

         } else {
            $(".cart__toggle").removeClass("cart__toggle-open");
            $(".cart__mini").removeClass("cart__opened");
         }

         $(".cart__mini.cart__opened").animate({ scrollTop: $(".cart__mini.cart__opened ul").height() }, 0)
      },
      addCarrinho : (state, produto) => {
         let verificaExistencia = state.carrinho.itens.findIndex(item => item.id == produto.id && item.tipoProduto == produto.tipoProduto)

         if (verificaExistencia != -1) return;

         state.carrinho.itens.unshift(produto)
         state.carrinho.valorTotal = state.carrinho.itens.map(produto => parseFloat(produto.valor) * 1).reduce((total, valor) => total += valor)
         localStorage.carrinho = JSON.stringify({'itens': state.carrinho.itens, 'valorTotal': state.carrinho.valorTotal, 'toggle': false})

         Vue.prototype.$toast.fire({
            icon: 'success',
            title: 'Adicionado ao carrinho'
         });
      },
      removeCarrinho : (state, index) => {
         state.carrinho.itens.splice(index, 1) 
         state.carrinho.valorTotal = state.carrinho.itens.map(produto => parseFloat(produto.valor) * 1).reduce((total, valor) => total += valor)
         localStorage.carrinho = JSON.stringify({'itens': state.carrinho.itens, 'valorTotal': state.carrinho.valorTotal, 'toggle': false})
      },
      limparCarrinho : state => {
         state.carrinho = {'itens': [], 'valorTotal': 0, 'toggle': false}
         localStorage.carrinho = JSON.stringify(state.carrinho)
      },
      pesquisar : (state, pesquisa) => {
         state.pesquisa.historico = JSON.parse(JSON.stringify(pesquisa))
         state.isCarregando = true;
         localStorage.monkeyPesquisa = JSON.stringify(state.pesquisa.historico)
         
         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicos/searchProdutos',
            params: {
               tipo: pesquisa.tag,
               filtro: pesquisa.filtro,
               nome: pesquisa.valor
            }
         }).then(response => {
            state.pesquisa.resultado = response.data;
            
         }).catch(function (error) {
            if (error.response != undefined) {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            router.push('/produtos').catch(function(){})
            state.isCarregando = false;

            $(".offcanvas__area").removeClass("opened");
            $(".body-overlay").removeClass("opened");
         });
      },
      resgatarPesquisa : (state, pesquisa) => {
         state.pesquisa.historico = pesquisa;
      },
      atualizarDadosUsuario : (state, usuario) => {
         state.dadosUsuario.whatsApp = usuario.whatsApp
         state.dadosUsuario.documento = usuario.documento
         state.dadosUsuario.email = usuario.email
         state.dadosUsuario.nomeCompleto = usuario.nomeCompleto
      },
      stripePgto : state => {
         state.isCarregando = true

         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'pagamento/stripePgto',
            headers: { 
               'Content-Type': 'application/json'
            },
            data: {
               title: 'Compra de pacote world fivem', 
               quantity: 1, 
               unit_price: parseFloat((state.carrinho.valorTotal).toFixed(2)),
            }
         }).then(function (response) {
            store.mutations.finalizar(state, {'id': response.data.id, 'url': response.data.url, 'formaPgto': 'Stripe'})
            window.open(response.data.url)

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  Swal.fire({
                     icon: 'error',
                     title: error.response.data
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      mercadoPago : state => {
         let transactionId = Math.floor(Math.random() * 999999999999999999999)
         state.isCarregando = true

         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'pagamento/mercadopago',
            headers: { 
               'Content-Type': 'application/json'
            },
            data: {
               items: [
                  {
                     title: 'Compra de pacote world fivem', 
                     quantity: 1, 
                     currency_id: "BRL", 
                     unit_price: parseFloat((state.carrinho.valorTotal).toFixed(2)),
                  }
               ],
               external_reference: transactionId,
               notification_url: state.urlRestExterna +'pagamento/alterarstatus?transactionId='+ transactionId
            }
         }).then(function (response) {
            store.mutations.finalizar(state, {'id': transactionId, 'url': response.data, 'formaPgto': 'Mercado Pago'})
            window.open(response.data)

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 400) {
                  Swal.fire({
                     icon: 'error',
                     title: error.response.data
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      finalizar : (state, transaction) => {
         state.isCarregando = true

         Vue.prototype.$axios({
            method: 'post',
            url: state.urlRest +'pagamento/checkout',
            headers: { 
               'Content-Type': 'application/json'
            },
            data: {
               'formaPgto': transaction.formaPgto, 
               'transactionId': transaction.id, 
               'urlPagamento': transaction.url, 
               'usuario': state.dadosUsuario, 
               'carrinho': state.carrinho.itens
            }
         }).then(() => {
            store.mutations.limparCarrinho(state)
            store.mutations.toggleCarrinho(state, false)

            Swal.fire({
               icon: 'success',
               title: 'Pedido realizado!'
            });
         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  store.mutations.deslogar(state)

                  Vue.prototype.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      searchAllPacotes : state => {
         state.isCarregando = true
         state.listaPacotes = [];

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicosAdmin/searchAllPacotes',

         }).then(response => {
				state.listaPacotes = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  store.mutations.deslogar(state)

                  Vue.prototype.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      searchAllCursos : state => {
         state.isCarregando = true
         state.listaCursos = [];

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicosAdmin/searchAllCursos',

         }).then(response => {
				state.listaCursos = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  store.mutations.deslogar(state)

                  Vue.prototype.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      searchAllScripts : state => {
         state.isCarregando = true
         state.listaScripts = [];

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicosAdmin/searchAllScripts',

         }).then(response => {
				state.listaScripts = response.data;

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  store.mutations.deslogar(state)

                  Vue.prototype.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      },
      buscarDonosScript : state => {   
         state.isCarregando = true
         state.listaDonosScript = []

         Vue.prototype.$axios({
            method: 'get',
            url: state.urlRest +'servicosAdmin/getDonos'

         }).then(response => {
				state.listaDonosScript = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  store.mutations.deslogar(state)

                  Vue.prototype.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               Vue.prototype.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            state.isCarregando = false
         });
      }
   },
   actions: {
      getData : context => {
         /* eslint-disable-next-line no-unused-vars */
         return new Promise((resolve, reject) => {
            context.commit('alternarTelaCarregamento', true)

            Vue.prototype.$axios({
               method: 'get',
               url: store.state.urlRest +'servicos/getDados'

            }).then(response => {
               context.commit('saveData', response.data)
               
            }).catch(function (error) {
               if (error.response != undefined) {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               } else {
                  Vue.prototype.$toast.fire({
                     icon: 'error',
                     title: error
                  });
               }
            }).finally(() => {
               context.commit('alternarTelaCarregamento', false)
               resolve()
            });
         })
      },
      /* eslint-disable-next-line no-unused-vars */
      imageError : ({commit}, e) => {
         e.target.src = imgError;
      },
      addCarrinho : ({commit}, produto) => {
         commit('addCarrinho', produto)
      },
      removeCarrinho : ({commit}, index) => {
         commit('removeCarrinho', index)
      },
      toggleCarrinho : ({commit}, toggle) => {
         commit('toggleCarrinho', toggle)
      },
      login : ({commit}, credenciais) => {
         commit('login', credenciais)
      },
      isRouteAdmin : ({commit}, isAdmin) => {
         commit('isRouteAdmin', isAdmin)
      },
      saveHistory : ({commit}, route) => {
         commit('saveHistory', route)
      },
      alternarTelaCarregamento : ({commit}, isCarregando) => {
         commit('alternarTelaCarregamento', isCarregando)
      },
      pesquisar : ({commit}, pesquisa) => {
         commit('pesquisar', pesquisa)
      },
      resgatarPesquisa : ({commit}, pesquisa) => {
         commit('resgatarPesquisa', pesquisa)
      },
      atualizarDadosUsuario : ({commit}, usuario) => {
         commit('atualizarDadosUsuario', usuario)
      },
      setPacotesGratuitos : context => context.commit('setPacotesGratuitos'),
      buscarDonosScript : context => context.commit('buscarDonosScript'),
      searchAllPacotes : context => context.commit('searchAllPacotes'),
      searchAllScripts : context => context.commit('searchAllScripts'),
      searchAllCursos : context => context.commit('searchAllCursos'),
      limparCarrinho : context => context.commit('limparCarrinho'),
      mercadoPago : context => context.commit('mercadoPago'),
      stripePgto : context => context.commit('stripePgto'),
      deslogar : context => context.commit('deslogar')
   },
   modules: {
   }
}

export default new Vuex.Store(store);