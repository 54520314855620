<template>
	<header>
      <div class="header__area">
         <div class="header__top d-none d-sm-block text-white">
            <div class="container px-0 px-md-3">
               <div class="row align-items-center">
                  <div class="col-xl-6 col-lg-5 d-none d-lg-block">
                     <div class="header__welcome">
                        <a href="javascript:;" class="limitador" v-if="isLogado">{{ $t("header.bemvindologado") }} <i>{{ dadosUsuario.userName }}</i> !</a>
                        <a href="javascript:;" class="limitador" v-else>{{ $t("header.bemvindovisitante") }}</a>
                     </div>
                  </div>
                  <div class="col-xl-6 col-lg-7 pe-lg-0">
                     <div class="header__action d-flex justify-content-center justify-content-lg-end">
                        <ul>
                           <li><router-link to="/termosCompras">{{ $t("header.termosCompras") }}</router-link></li>
                           <li><a href="javascript:;" @click="setRouteAdmin">{{ isLogado ? $t("header.areaCliente") : $t("header.login") }}</a></li>
                           <li v-if="isLogado"><a href="javascript:;" @click="deslogar">{{ $t("header.deslogar") }}</a></li>
                           <li>
                              <img :class="$i18n.locale == 'pt-BR' ? '' : 'filter-grayscale'" @click="changeLang('pt-BR')" src="@/assets/cliente/img/pt-BR.png" height="20" title="Português" class="cursor-pointer me-3" />
                              <img :class="$i18n.locale == 'en-US' ? '' : 'filter-grayscale'" @click="changeLang('en-US')" src="@/assets/cliente/img/en-US.png" height="20" title="English" class="cursor-pointer" />
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="header__info py-xxl-3">
            <div class="container">
               <div class="row align-items-center">
                  <!-- Logo -->
                  <div class="col-lg-2 mb-2 mb-md-4 mb-lg-0">
                     <div class="header__info-left d-flex justify-content-center justify-content-lg-end align-items-center">
                        <div class="logo">
                           <router-link to="/"><img src="@/assets/cliente/img/logo/logo-black.png" alt="logo"></router-link>
                        </div>
                     </div>
                  </div>

                  <!-- Pesquisa -->
                  <div class="col-lg-8 col-md-10 d-none d-md-block">
                     <div class="header__info-right">
                        <div class="header__search w-100">
                           <form action="javascript:;">
                              <div class="header__search-box">
                                 <input type="text" :placeholder="$t('header.pesquisa')" v-model="pesquisa.valor" @keyup.enter="pesquisar">
                                 <button @click="pesquisar"><i class="far fa-search d-inline d-sm-none"></i><span class="d-none d-sm-inline">{{ $t('header.pesquisaBotao') }}</span></button>
                              </div>
                              <div class="header__search-cat">
                                 <v-select :searchable="false" :clearable="false" class="border-0" :options="$t('ordenadores')" label="value" :reduce="o => o.key" v-model="pesquisa.filtro" />
                              </div>
                           </form>
                        </div>
                     </div>
                  </div>

                  <!-- Carrinho -->
                  <div class="col-md-2 d-flex d-md-block justify-content-between justify-content-md-start">
                     <button type="button" class="side-menu-btn offcanvas-toggle-btn ml-25 d-block d-md-none" @click="abrirMenu">
                        <i class="far fa-bars font-20 mb-2"></i>
                     </button>
                     <div class="cart__mini-wrapper p-relative pt-1" v-show="isLogado">
                        <a href="javascript:;" class="cart__toggle" @click="abrirCarrinho">
                           <span class="cart__total-item" v-if="carrinho.itens.length > 0">{{ carrinho.itens.length }}</span>
                        </a>
                        <div class="cart__mini pb-4 pt-3">
                           <div class="cart__close">
                              <button type="button" class="cart__close-btn" @click="fecharCarrinho"><i class="fal fa-times"></i></button>
                           </div>
                           <ul>
                              <li>
                                 <div class="cart__title pb-12">
                                    <h4 class="font-16">{{ $t('header.carrinho') }}</h4>
                                    <span class="ms-2 text-secondary" v-if="carrinho.itens.length > 0"> {{ carrinho.itens.length }} {{ $t('header.carrinhoDescQtdProdutos') }}</span>
                                 </div>
                              </li>

                              <carrinho v-for="(produto, index) in carrinho.itens" :key="index" :produto="produto" :index="index" />
                              
                              <li>
                                 <div class="cart__item d-flex justify-content-between align-items-center" v-if="carrinho.itens.length == 0">
                                    <div class="cart__inner d-flex mx-auto my-5">
                                       <div class="cart__details">
                                          <h6><a href="javascript:;">{{ $t('header.carrinhoVazio') }}</a></h6>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li v-if="carrinho.itens.length > 0">
                                 <div class="cart__sub d-flex justify-content-between align-items-center py-12">
                                    <h6>Total</h6>
                                    <span class="cart__sub-total" v-if="parseFloat(carrinho.valorTotal) > 0">R${{ parseFloat(carrinho.valorTotal).toLocaleString('pt-br', {minimumFractionDigits: 2, maximumFractionDigits:2}) }}</span>
                                    <span class="cart__sub-total" v-else>{{ $t('header.carrinhoGratuito') }}</span>
                                 </div>
                              </li>
                              <li v-if="carrinho.itens.length > 0">
                                 <div class="row align-items-center">
                                    <div class="px-1" :class="parseFloat(carrinho.valorTotal) > 0 ? 'col-4' : 'col-6'">
                                       <a href="javascript:;" class="t-y-btn t-y-btn-2 px-12 w-100 d-flex flex-wrap align-items-center" @click="limparCarrinho">
                                          <span class="w-100">{{ $t('header.carrinhoLimpar1') }}</span><span class="w-100 lh-1 mb-1">{{ $t('header.carrinhoLimpar2') }}</span>
                                       </a>
                                    </div>
                                    <div class="col-4 px-1" v-if="parseFloat(carrinho.valorTotal) > 0">
                                       <a href="javascript:;" class="t-y-btn px-12 w-100 d-flex flex-wrap align-items-center" @click="finalizarCompra('Stripe')">
                                          <img src="@/assets/cliente/img/stripe.png" class="icone-pgto" /><span class="col">{{ $t('header.carrinhoFinalizar1') }}</span>
                                          <span class="w-100 weight-400 lh-1 mb-1">{{ $t('header.carrinhoFinalizarLocal1') }}</span>
                                       </a>
                                    </div>
                                    <div class="col-4 px-1" v-if="parseFloat(carrinho.valorTotal) > 0">
                                       <a href="javascript:;" class="t-y-btn px-12 w-100 d-flex flex-wrap align-items-center" @click="finalizarCompra('MercadoPago')">
                                          <img src="@/assets/cliente/img/mercadoPago.png" class="icone-pgto" /><span class="col">{{ $t('header.carrinhoFinalizar1') }}</span>
                                          <span class="w-100 weight-400 lh-1 mb-1">{{ $t('header.carrinhoFinalizarLocal2') }}</span>
                                       </a>
                                    </div>
                                    <div class="col-6 px-1" v-else>
                                       <a href="javascript:;" class="t-y-btn px-12 w-100 d-flex flex-wrap align-items-center" @click="finalizarCompra">
                                          <span class="w-100">{{ $t('header.carrinhoFinalizar1') }}</span><span class="w-100 lh-1 mb-1">{{ $t('header.carrinhoFinalizar2') }}</span>
                                       </a>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                     <a href="javascript:;" @click="setRouteAdmin" class="login__icon mt-md-1 mb-2 mb-md-0" v-show="!isLogado"></a>
                  </div>
               </div>

               <!-- Categorias -->
               <div class="row align-items-center mt-1 d-none d-xxl-flex" v-if="$t('categorias') != null && $t('categorias').length > 0">
                  <div class="col-xl-2 col-lg-3"></div>
                  <div class="col-xl-8 col-lg-6">
                     <div class="header__action main-menu">
                        <ul class="d-flex justify-content-center color-theme">
                           <categoria v-for="(categoria, index) in $t('categorias')" :key="index" :categoria="categoria" :page="'Header'" :length="$t('categorias').length + 1" />
                        </ul>
                     </div>
                  </div>
                  <div class="col-xl-2 col-lg-3"></div>
               </div>
            </div>
         </div>
      </div>
   </header>
</template>

<script>

import $ from 'jquery'
import router from '@/router'
import { mapState } from 'vuex'
import categoria from '@/components/home/Categoria.vue'
import carrinho from '@/components/perfil/Carrinho.vue'

export default {
	name: 'Header',
   data : function () {
      return {
         pesquisa: {'tag': 'Todos', 'valor': '', 'filtro': 'Relevância'}
      }
   },
   computed: {
		... mapState({
			dadosUsuario: state => state.dadosUsuario,
			carrinho: state => state.carrinho,
			isLogado: state => state.isLogado
		})
	},
   components: {
      categoria, carrinho
   },
   methods: {
      setRouteAdmin : function () {
         this.$store.dispatch('isRouteAdmin', true)
         router.push('/admInicio')
      },
      pesquisar : function () {
         this.$store.dispatch('pesquisar', JSON.parse(JSON.stringify(this.pesquisa)))
      },
      deslogar : function () {
			this.$store.dispatch('deslogar')
		},
      abrirMenu : function () {
         $(".offcanvas__area").addClass("opened");
         $(".body-overlay").addClass("opened");
      },
      abrirCarrinho : function () {
         $(".cart__mini").addClass("cart__opened");

         if (this.carrinho.toggle == false) {
            this.$store.dispatch('toggleCarrinho', true)

         } else if (this.carrinho.toggle == true) {
            this.$store.dispatch('toggleCarrinho', false)
         }
      },
      fecharCarrinho : function () {
         this.$store.dispatch('toggleCarrinho', false)
      },
      finalizarCompra : function (formaPgto) {
         if (parseFloat(this.carrinho.valorTotal) > 0) {
            if (formaPgto == 'MercadoPago') {
               this.$store.dispatch('mercadoPago')
            } else if (formaPgto == 'Stripe') {
               this.$store.dispatch('stripePgto')
            }
         } else {
            this.$store.dispatch('setPacotesGratuitos')
         }
      },
      limparCarrinho : function () {
         this.$store.dispatch('limparCarrinho')
      },
      changeLang : function (lang) {
         localStorage.lang = lang;
         this.$i18n.locale = lang;
      }
   }
}

</script>

<style scoped>

.btn-category {
   color: #fff !important;
   font-size: 13px !important;
   box-shadow: none !important;
   padding: 3px 12px !important;
   text-transform: capitalize !important;
   font-weight: 400 !important;
}

.header__info-right {
   padding-top: 6px;
   padding-bottom: 6px;
}

.icone-pgto {
   width: 20px;
   height: 20px;
   object-fit: contain;
   margin-right: 6px;
   /* filter: grayscale(1) brightness(10); */
}

</style>