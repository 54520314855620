import $ from 'jquery'
import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
   // Cliente
   {
      path: '/',
      name: 'Home',
      component: () => import('../views/Home.vue')
   },
   {
      path: '/produtos',
      name: 'Produtos',
      component: () => import('../views/Produtos.vue')
   },
   {
      path: '/produto/:tipo/:id',
      name: 'Produto',
      component: () => import('../views/Produto.vue')
   },
   {
      path: '/termosCompras',
      name: 'Termos de compras',
      component: () => import('../views/TermosCompras.vue')
   },
   // Admin
   {
      path: '/admInicio',
      name: 'AdmInício',
      component: () => import('../views/admin/Inicio.vue')
   },
   {
      path: '/admMeusCursos',
      name: 'AdmMeus_cursos',
      component: () => import('../views/admin/MeusCursos.vue')
   },
   {
      path: '/admClassroom/:idModulo',
      name: 'AdmClassroom',
      component: () => import('../views/admin/Classroom.vue')
   },
   {
      path: '/admAula/:idModulo/:idBloco/:idAula',
      name: 'AdmAula',
      component: () => import('../views/admin/Aula.vue')
   },
   {
      path: '/admMeusScripts',
      name: 'AdmMeus_scripts',
      component: () => import('../views/admin/MeusScripts.vue')
   },
   {
      path: '/admCompras',
      name: 'AdmHistórico_de_compras',
      component: () => import('../views/admin/Compras.vue')
   },
   {
      path: '/admAjuda',
      name: 'AdmAjuda',
      component: () => import('../views/admin/Ajuda.vue')
   },
   {
      path: '/admGerenciar',
      name: 'AdmGerenciar',
      component: () => import('../views/admin/Gerenciar.vue')
   },
   {
      path: '/admModulo/:id',
      name: 'AdmModulo',
      component: () => import('../views/admin/Modulo.vue')
   },
   {
      path: '/admEstatisticas',
      name: 'AdmEstatisticas',
      component: () => import('../views/admin/Estatisticas.vue')
   },
   {
      path: '/admPedidos',
      name: 'AdmPedidos',
      component: () => import('../views/admin/Pedidos.vue')
   },
   {
      path: '/admUsuarios',
      name: 'AdmUsuarios',
      component: () => import('../views/admin/Usuarios.vue')
   },
   {
      path: '/admLogs',
      name: 'AdmLogs',
      component: () => import('../views/admin/Logs.vue')
   },
   {
      path: '/admPerfil',
      name: 'AdmPerfil',
      component: () => import('../views/admin/Perfil.vue')
   },
   {
      path: '/admDiscord',
      name: 'AdmDiscord',
      component: () => import('../views/admin/Discord.vue')
   }
]

const router = new VueRouter({
   mode: 'history',
   base: process.env.BASE_URL,
   routes
})

router.beforeEach((to, from, next) => {
   $(".offcanvas__area").removeClass("opened");
   $(".body-overlay").removeClass("opened");
   $("#app > .wrapper").removeClass("toggled");

   store.dispatch('isRouteAdmin', String(to.path).startsWith('/adm'))
   store.dispatch('saveHistory', to)

   document.title = 'World Fivem - '+ String(to.name).replace(/_/g, " ").replace(/-/g, "/").replace("Adm", "");
   
   if (localStorage.monkeyPesquisa) {
      if (to.name == 'Produtos') {
         store.dispatch('resgatarPesquisa', JSON.parse(localStorage.monkeyPesquisa))
      } else if (to.name != 'Produto') {
         localStorage.removeItem('monkeyPesquisa')
      }
   }

   if (to.path.includes('adm')) {
      if (!store.state.isLogado) {
         next('/')
      } else {
         next()
      }
   } else {
      next()
   }
})

export default router
