export default {
   "en-US": {
      categorias: [
         {'key': 'Todos', 'value': 'All'},
         {'key': 'Bases', 'value': 'Bases'},
         {'key': 'Scripts', 'value': 'Scripts'},
         {'key': 'Tools', 'value': 'Tools'},
         {'key': 'Mapas', 'value': 'Maps'},
         {'key': 'Favelas', 'value': 'Slums'},
         {'key': 'Veículos', 'value': 'Vehicles'},
         {'key': 'Peds', 'value': 'Peds'},
         {'key': 'Armas', 'value': 'Weapons'},
         {'key': 'Roupas', 'value': 'Clothes'},
         {'key': 'Props', 'value': 'Props'},
         {'key': 'Códigos', 'value': 'Codes'},
         {'key': 'Grátis', 'value': 'Free'}
      ],
      ordenadores: [
         {'key': 'Relevância', 'value': 'Relevance'},
         {'key': 'Mais recentes', 'value': 'Most recent'},
         {'key': 'Mais antigos', 'value': 'Oldest'},
         {'key': 'Mais vistos', 'value': 'Most viewed'},
         {'key': 'Mais vendidos', 'value': 'Best Sellers'},
         {'key': 'Em promoção', 'value': 'On sale'},
         {'key': 'Melhor avaliação', 'value': 'Best rating'}
      ],
      header: {
         bemvindologado: 'Welcome',
         bemvindovisitante: 'Welcome visitor!',
         termosCompras: 'Terms of purchase',
         areaCliente: 'Customer area',
         login: 'Login',
         deslogar: 'Log out',
         pesquisa: 'Search by package or script name',
         pesquisaBotao: 'Search',
         carrinho: 'Cart',
         carrinhoVazio: 'Empty cart',
         carrinhoGratuito: 'Free',
         carrinhoLimpar1: 'Clear',
         carrinhoLimpar2: 'cart',
         carrinhoFinalizar1: 'Checkout',
         carrinhoFinalizar2: 'buy',
         carrinhoFinalizarLocal1: 'Global',
         carrinhoFinalizarLocal2: 'Brazil',
         carrinhoDescQtdProdutos: 'product(s)'
      },
      footer: {
         mapaSite: 'Sitemap',
         termosCompras: 'Terms of purchase',
         areaCliente: 'Customer area'
      },
      home: {
         destaques: 'Highlights',
         tecnologias: 'Technologies used'
      },
      produtos: {
         titulo: 'Products',
         semResultados: 'No products found'
      },
      produto: {
         titulo: 'Product',
         gratuito: 'Free',
         exclusivo: 'Exclusive',
         otimizado: 'Optimized',
         testado: 'Tested',
         semVirus: 'No viruses',
         addCarrinho: 'Add to Cart',
         documentacao: 'Documentation',
         descricao: 'Description',
         imagens: 'Images',
         conteudo: 'Content'
      },
      termosCompras: {
         boasCompras: 'Terms of good shopping',
         boasComprasDesc: 'Welcome to our platform! Here, we prioritize your shopping experience, ensuring transparency, security and satisfaction. Our shopping terms reflect our commitment to offering quality products and exceptional service. When browsing our website, you can trust that we are always looking out for the best for you, our valued customer. We look forward to serving you and making your shopping journey with us simple, reliable and rewarding.',
         qualidade: 'Quality Guaranteed',
         qualidadeDesc: 'All products in our catalog are carefully selected and tested to ensure the highest possible quality. We are committed to offering only products that meet our rigorous standards.',
         transparencia: 'Total Transparency',
         transparenciaDesc: 'Our purchasing process is transparent in every aspect. From pricing to return policies, we strive to provide clear and accessible information so you can make informed choices.',
         seguranca: 'Safety First',
         segurancaDesc: 'Your security is our top priority. We use advanced security technologies to protect your personal information and ensure safe transactions on our website.',
         atendimento: 'Exceptional Customer Service',
         atendimentoDesc: "Our support team is always ready to help. If you have any questions, issues, or need assistance, please don't hesitate to contact us via discord. We are here to ensure your complete satisfaction.",
         experiencia: 'Trusted Shopping Experience',
         experienciaDesc: 'We want your shopping experience with us to be positive and hassle-free. We are committed to providing a reliable and enjoyable shopping environment where you can find exactly what you are looking for, with ease and peace of mind.'
      },
      sessaoExpirada: 'Session expired!',
      menuNavbar: {
         meuPerfil: 'My profile',
         deslogar: 'Log out',
         temas: 'Themes',
         claro: 'Light',
         escuro: 'Dark'
      },
      menuLateral: {
         inicio: 'Home',
         cliente: 'Customer',
         meusCursos: 'My courses',
         meusScripts: 'My scripts',
         historicoCompras: 'Purchase history',
         ajuda: 'Help',
         administrativo: 'Administrative',
         gerenciar: 'Manage',
         estatisticas: 'Statistics',
         pedidos: 'Orders',
         usuarios: 'Users',
         logs: 'Logs'
      },
      admInicio: {
         bemVindo1: 'Welcome to',
         bemVindo2: 'Your portal for everything about Fivem',
         bemVindo3: 'Find a variety of programming courses for FiveM',
         bemVindo4: 'Purchase favela scripts, weapon skins, vehicles, factions, and more.',
         bemVindoBotao: 'Check the store',
         atalhoPerfil: 'Profile',
         atalhoPerfilDesc: 'View your user profile.',
         atalhoCursos: 'My courses',
         atalhoCursosDesc: 'See all your acquired courses.',
         atalhoScripts: 'My scripts',
         atalhoScriptsDesc: 'See all your acquired scripts.',
         atalhoHistorico: 'History',
         atalhoHistoricoDesc: 'Track your purchase more closely.'
      },
      admMeusCursos: {
         semResultados: 'You have no acquired courses'
      },
      admClassroom: {
         aulas: 'Lessons'
      },
      admAula: {
         descricao: 'Description'
      },
      admMeusScripts: {
         semResultados: 'You have no acquired scripts',
         documentacao: 'Documentation',
         linkSvn: 'SVN link',
         loginSvn: 'SVN login',
         senhaSvn: 'SVN password',
         video: 'Preview video',
         imagens: 'Images'
      },
      script: {
         abrir: 'Open',
         avaliar: 'Review',
         avaliarDesc: 'Review product',
         botaoConfirmar: 'Confirm',
         botaoCancelar: 'Cancel',
         reportar: 'Report link',
         reportarDesc: 'Report link?',
         reportarSucesso: 'Script reported!'
      },
      admCompras: {
         pacotes: 'Packages',
         novaGuia: 'Open in new tab',
         pagamento: 'Payment URL',
         semResultados: 'No results found'
      },
      admAjuda: {
         pesquisar: 'Search',
         adicionar: 'Add',
         adicionarTitulo: 'Add question',
         editarTitulo: 'Edit question',
         descricao: 'Description',
         campoTitulo: 'Title',
         campoDescricao: 'Description',
         validacaoTitulo: 'Enter a title!',
         validacaoDescricao: 'Enter a description!',
         botaoSalvar: 'Save',
         botaoFechar: 'Close',
         salvarSucesso: 'Question saved!',
         deletarDesc: 'Do you want to delete?',
         botaoConfirmar: 'Confirm',
         botaoCancelar: 'Cancel',
         deletarSucesso: 'Question deleted!'
      },
      admGerenciar: {
         filtro: 'Filter',
         filtros: [
            {'key': 'Pacotes', 'value': 'Packages'},
            {'key': 'Cursos', 'value': 'Courses'},
            {'key': 'Scripts', 'value': 'Scripts'}
         ],
         pesquisar: 'Search',
         pesquisarDesc: 'Search by name',
         adicionar: 'Add',
         addPacoteTitulo: 'Add package',
         editPacoteTitulo: 'Edit package',
         addCursoTitulo: 'Add course',
         editCursoTitulo: 'Edit course',
         addScriptTitulo: 'Add script',
         editScriptTitulo: 'Edit script',
         infoPacoteTitulo: 'Package information',
         infoCursoTitulo: 'Course information',
         infoScriptTitulo: 'Script information',
         campoTitulo: 'Title',
         campoValor: 'Value',
         campoDescricao: 'Description',
         campoCategorias: 'Categories',
         campoLinkDocumentacao: 'Documentation link',
         campoDono: 'Owner',
         campoOrdenacao: 'Ordering',
         campoPromocao: 'Promotion',
         campoDesabilitado: 'Hide from store',
         campoDestaque: 'Featured',
         campoBanner: 'Product banner',
         nenhumBanner: 'No banner found',
         campoConteudo: 'Content',
         campoCursos: 'Package courses',
         campoDescQtdCursos: 'course(s)',
         nenhumCurso: 'No course found',
         campoScripts: 'Package scripts',
         campoDescQtdScripts: 'script(s)',
         nenhumScript: 'No script found',
         campoCodigo: 'Code',
         campoLinkDrive: 'Drive link',
         campoLoginSvn: 'SVN login',
         campoSenhaSvn: 'SVN password',
         campoLinkSvn: 'SVN link',
         campoVideo: 'Preview video',
         nenhumVideo: 'No video found',
         campoExclusivo: 'Exclusive',
         campoOtimizado: 'Optimized',
         campoTestado: 'Tested',
         campoSeguro: 'No virus',
         campoImagens: 'Images',
         botaoSalvar: 'Save',
         botaoFechar: 'Close',
         validacaoTitulo: 'Enter a title!',
         validacaoCategoria: 'Enter a category!',
         validacaoValor: 'Enter a value!',
         validacaoTamanhoArquivo: 'File size exceeded',
         validacaoTamanhoArquivoDesc: 'File exceeds 10MB',
         validacaoLimiteImagens: 'Image limit reached',
         validacaoLimiteImagensDesc: 'Maximum image limit exceeded (3).',
         validacaoCodigo: 'Enter a code!',
         pacoteSalvoSucesso: 'Package saved!',
         deletarPacoteDesc: 'Delete package?',
         deletarPacoteSucesso: 'Package deleted!',
         cursoSalvoSucesso: 'Course saved!',
         deletarCursoDesc: 'Delete course?',
         deletarCursoSucesso: 'Course deleted!',
         scriptSalvoSucesso: 'Script saved!',
         deletarScriptDesc: 'Delete script?',
         deletarScriptSucesso: 'Script deleted!',
         botaoConfirmar: 'Confirm',
         botaoCancelar: 'Cancel'
      },
      pacote: {
         abrir: 'Open',
         editar: 'Edit',
         excluir: 'Delete',
      },
      admModulo: {
         aulas: 'Lessons',
         addAula: 'Add lesson',
         editAula: 'Edit lesson',
         addBloco: 'Add block',
         editBloco: 'Edit block',
         infoAulaTitulo: 'Lesson information',
         campoTitulo: 'Title',
         campoDescricao: 'Description',
         campoVideo: 'Video',
         nenhumVideo: 'No video found',
         validacaoTitulo: 'Enter a title!',
         deletarBlocoDesc1: 'Delete block?',
         deletarBlocoDesc2: 'The lessons in the block will be deleted',
         deletarBlocoSucesso: 'Block deleted!',
         deletarAulaDesc: 'Delete lesson?',
         deletarAulaSucesso: 'Lesson deleted!',
         botaoSalvar: 'Save',
         botaoFechar: 'Close',
         botaoConfirmar: 'Confirm',
         botaoCancelar: 'Cancel'
      },
      admEstatisticas: {
         filtro: 'Filter',
         filtros: [
            {'key': 'Dono', 'value': 'Owner'},
            {'key': 'Autor', 'value': 'Author'},
            {'key': 'Categoria', 'value': 'Category'},
            {'key': 'Pacote', 'value': 'Package'},
            {'key': 'Script', 'value': 'Script'}
         ],
         pacote: 'Package',
         pacoteDesc: 'All packages',
         script: 'Script',
         scriptDesc: 'All scripts',
         dono: 'Owner',
         donoDesc: 'All owners',
         categoria: 'Category',
         categoriaDesc: 'All categories',
         autor: 'Author',
         autorDesc: 'Search by author name',
         data: 'Date',
         semResultados: 'No results',
         vendasDiarias: 'Daily sales',
         maisVendidos: 'Best sellers'
      },
      admPedidos: {
         filtro: 'Filter',
         filtros: [
            {'key': 'Owner', 'value': 'Owner'},
            {'key': 'Author', 'value': 'Author'},
            {'key': 'Category', 'value': 'Category'},
            {'key': 'Package', 'value': 'Package'},
            {'key': 'Script', 'value': 'Script'}
         ],
         pacote: 'Package',
         pacoteDesc: 'All packages',
         script: 'Script',
         scriptDesc: 'All scripts',
         dono: 'Owner',
         donoDesc: 'All owners',
         categoria: 'Category',
         categoriaDesc: 'All categories',
         autor: 'Author',
         autorDesc: 'Search by author name',
         data: 'Date',
         semResultados: 'No results',
         semResultados2: 'No results found',
         pacotes: 'Packages',
         pagamento: 'Payment',
         verPedidoTitulo: 'Order',
         infoPedidoTitulo: 'Order information',
         nenhumPacote: 'No package found',
         campoNomeCliente: 'Customer name',
         campoIdCliente: 'Customer ID',
         campoValor: 'Total value',
         campoPagamento: 'Payment method',
         campoTransacao: 'Transaction ID',
         campoDataCompra: 'Order placed',
         campoMovimentacao: 'Last movement',
         campoUrlPagamento: 'Payment URL',
         botaoFechar: 'Close',
         alterarStatus: 'Change status',
         alterarStatusDesc: 'Select a status',
         alterarStatusValidacao: 'Invalid option!',
         alterarStatusSucesso: 'Status saved!',
         botaoConfirmar: 'Confirm',
         botaoCancelar: 'Cancel',
         status: {
            'Aguardando pagamento': 'Awaiting payment',
            'Cancelado': 'Cancelled',
            'Finalizado': 'Completed'
         }
      },
      admUsuarios: {
         pesquisar: 'Search',
         pesquisarDesc: 'Search by name, username, Whatsapp, or email',
         permissoes: 'Permissions',
         pacotes: 'Packages',
         jornada: 'Journey',
         editar: 'Edit',
         excluir: 'Delete',
         adicionar: 'Add',
         addPacote: 'Add package',
         semResultados: 'No results found',
         botaoSalvar: 'Save',
         botaoVoltar: 'Back',
         botaoFechar: 'Close',
         jornadaGeral: 'General',
         jornadaAulas: 'Lessons',
         campoNome: 'Full name',
         campoCpfCnpj: 'Document',
         campoAdministrador: 'Administrator',
         campoSuporte: 'Support',
         salvoSucesso: 'User saved!',
         deletarDesc: 'Delete user?',
         deletarSucesso: 'Course deleted!',
         botaoConfirmar: 'Confirm',
         botaoCancelar: 'Cancel',
         removerPacoteDesc: 'Delete package?',
         removerPacoteSucesso: 'Package deleted!',
         addPacoteDesc: 'Add package?',
         addPacoteSucesso: 'Package added!'
      },
      admLogs: {
         filtro: 'Filter',
         filtros: [
            {'key': 'Aula visualizada', 'value': 'Lesson viewed'},
            {'key': 'Compra', 'value': 'Purchase'},
            {'key': 'Usuario', 'value': 'User'}
         ],
         semResultados: 'No results found',
         verMais: 'See more'
      },
      admPerfil: {
         cursos: 'Courses',
         pacotes: 'Packages',
         editar: 'Edit',
         campoNome: 'Full name',
         campoCpfCnpj: 'Document',
         botaoSalvar: 'Save',
         botaoFechar: 'Close',
         salvarSucesso: 'Data saved!'
      }
   },
   "pt-BR": {
      categorias: [
         {'key': 'Todos', 'value': 'Todos'},
         {'key': 'Bases', 'value': 'Bases'},
         {'key': 'Scripts', 'value': 'Scripts'},
         {'key': 'Tools', 'value': 'Tools'},
         {'key': 'Mapas', 'value': 'Mapas'},
         {'key': 'Favelas', 'value': 'Favelas'},
         {'key': 'Veículos', 'value': 'Veículos'},
         {'key': 'Peds', 'value': 'Peds'},
         {'key': 'Armas', 'value': 'Armas'},
         {'key': 'Roupas', 'value': 'Roupas'},
         {'key': 'Props', 'value': 'Props'},
         {'key': 'Códigos', 'value': 'Códigos'},
         {'key': 'Grátis', 'value': 'Grátis'}
      ],
      ordenadores: [
         {'key': 'Relevância', 'value': 'Relevância'},
         {'key': 'Mais recentes', 'value': 'Mais recentes'},
         {'key': 'Mais antigos', 'value': 'Mais antigos'},
         {'key': 'Mais vistos', 'value': 'Mais vistos'},
         {'key': 'Mais vendidos', 'value': 'Mais vendidos'},
         {'key': 'Em promoção', 'value': 'Em promoção'},
         {'key': 'Melhor avaliação', 'value': 'Melhor avaliação'}
      ],
      header: {
         bemvindologado: 'Bem-vindo',
         bemvindovisitante: 'Bem-vindo visitante!',
         termosCompras: 'Termos de compras',
         areaCliente: 'Área do cliente',
         login: 'Fazer login',
         deslogar: 'Deslogar',
         pesquisa: 'Busque pelo nome do pacote ou script',
         pesquisaBotao: 'Pesquisar',
         carrinho: 'Carrinho',
         carrinhoVazio: 'Carrinho vazio',
         carrinhoGratuito: 'Gratuito',
         carrinhoLimpar1: 'Limpar',
         carrinhoLimpar2: 'carrinho',
         carrinhoFinalizar1: 'Finalizar',
         carrinhoFinalizar2: 'compra',
         carrinhoFinalizarLocal1: 'Global',
         carrinhoFinalizarLocal2: 'Brasil',
         carrinhoDescQtdProdutos: 'produto(s)'
      },
      footer: {
         mapaSite: 'Mapa do site',
         termosCompras: 'Termos de compras',
         areaCliente: 'Área do cliente'
      },
      home: {
         destaques: 'Destaques',
         tecnologias: 'Tecnologias usadas'
      },
      produtos: {
         titulo: 'Produtos',
         semResultados: 'Nenhum produto encontrado'
      },
      produto: {
         titulo: 'Produto',
         gratuito: 'Gratuito',
         exclusivo: 'Exclusivo',
         otimizado: 'Otimizado',
         testado: 'Testado',
         semVirus: 'Sem vírus',
         addCarrinho: 'Adicionar ao carrinho',
         documentacao: 'Documentação',
         descricao: 'Descrição',
         imagens: 'Imagens',
         conteudo: 'Conteúdo'
      },
      termosCompras: {
         boasCompras: 'Termos de boas compras',
         boasComprasDesc: 'Bem-vindo à nossa plataforma! Aqui, priorizamos a sua experiência de compra, garantindo transparência, segurança e satisfação. Nossos termos de boas compras refletem nosso compromisso em oferecer produtos de qualidade e um serviço excepcional. Ao navegar em nosso site, você pode confiar que estamos sempre buscando o melhor para você, nosso cliente valorizado. Estamos ansiosos para servi-lo e tornar sua jornada de compra conosco simples, confiável e gratificante.',
         qualidade: 'Qualidade Garantida',
         qualidadeDesc: 'Todos os produtos em nosso catálogo são cuidadosamente selecionados e testados para garantir a mais alta qualidade possível. Estamos comprometidos em oferecer apenas produtos que atendam aos nossos padrões rigorosos.',
         transparencia: 'Transparência Total',
         transparenciaDesc: 'Nosso processo de compra é transparente em todos os aspectos. Desde os preços até as políticas de devolução, nos esforçamos para fornecer informações claras e acessíveis para que você possa fazer escolhas informadas.',
         seguranca: 'Segurança em Primeiro Lugar',
         segurancaDesc: 'Sua segurança é nossa prioridade máxima. Utilizamos tecnologias de segurança avançadas para proteger suas informações pessoais e garantir transações seguras em nosso site.',
         atendimento: 'Atendimento ao Cliente Excepcional',
         atendimentoDesc: 'Nossa equipe de suporte está sempre pronta para ajudar. Se você tiver alguma dúvida, problema ou precisar de assistência, não hesite em entrar em contato conosco via discord. Estamos aqui para garantir sua total satisfação.',
         experiencia: 'Experiência de Compra Confiável',
         experienciaDesc: 'Queremos que sua experiência de compra conosco seja positiva e sem complicações. Estamos empenhados em fornecer um ambiente de compra confiável e agradável, onde você possa encontrar exatamente o que procura, com facilidade e tranquilidade.'
      },
      sessaoExpirada: 'Sessão expirada!',
      menuNavbar: {
         meuPerfil: 'Meu perfil',
         deslogar: 'Deslogar',
         temas: 'Temas',
         claro: 'Claro',
         escuro: 'Escuro',
      },
      menuLateral: {
         inicio: 'Início',
         cliente: 'Cliente',
         meusCursos: 'Meus cursos',
         meusScripts: 'Meus scripts',
         historicoCompras: 'Histórico de compras',
         ajuda: 'Ajuda',
         administrativo: 'Administrativo',
         gerenciar: 'Gerenciar',
         estatisticas: 'Estatísticas',
         pedidos: 'Pedidos',
         usuarios: 'Usuários',
         logs: 'Logs'
      },
      admInicio: {
         bemVindo1: 'Seja bem vindo ao',
         bemVindo2: 'Seu portal com tudo para Fivem',
         bemVindo3: 'Encontre variedades de cursos de programação para FiveM',
         bemVindo4: 'Adquira scripts de favelas, skin de armas, viaturas, facções e muito mais.',
         bemVindoBotao: 'Confira a loja',
         atalhoPerfil: 'Perfil',
         atalhoPerfilDesc: 'Veja seu perfil de usuário.',
         atalhoCursos: 'Meus cursos',
         atalhoCursosDesc: 'Veja todos os seus cursos adquiridos.',
         atalhoScripts: 'Meus scripts',
         atalhoScriptsDesc: 'Veja todos os seus scripts adquiridos.',
         atalhoHistorico: 'Histórico',
         atalhoHistoricoDesc: 'Acompanhe sua compra mais de perto.'
      },
      admMeusCursos: {
         semResultados: 'Você não tem cursos adquiridos'
      },
      admClassroom: {
         aulas: 'Aulas'
      },
      admAula: {
         descricao: 'Descrição'
      },
      admMeusScripts: {
         semResultados: 'Você não tem scripts adquiridos',
         documentacao: 'Documentação',
         linkSvn: 'Link do SVN',
         loginSvn: 'Login do SVN',
         senhaSvn: 'Senha do SVN',
         video: 'Vídeo prévio',
         imagens: 'Imagens'
      },
      script: {
         abrir: 'Abrir',
         avaliar: 'Avaliar',
         avaliarDesc: 'Avaliar produto',
         botaoConfirmar: 'Confirmar',
         botaoCancelar: 'Cancelar',
         reportar: 'Reportar link',
         reportarDesc: 'Reportar link?',
         reportarSucesso: 'Script reportado!'
      },
      admCompras: {
         pacotes: 'Pacotes',
         novaGuia: 'Abrir em nova guia',
         pagamento: 'Url pagamento',
         semResultados: 'Nenhum resultado encontrado'
      },
      admAjuda: {
         pesquisar: 'Pesquisar',
         adicionar: 'Adicionar',
         adicionarTitulo: 'Adicionar dúvida',
         editarTitulo: 'Editar dúvida',
         descricao: 'Descrição',
         campoTitulo: 'Título',
         campoDescricao: 'Descrição',
         validacaoTitulo: 'Insira um título!',
         validacaoDescricao: 'Insira uma descrição!',
         botaoSalvar: 'Salvar',
         botaoFechar: 'Fechar',
         salvarSucesso: 'Dúvida salva!',
         deletarDesc: 'Deseja deletar?',
         botaoConfirmar: 'Confirmar',
         botaoCancelar: 'Cancelar',
         deletarSucesso: 'Dúvida deletada!',
      },
      admGerenciar: {
         filtro: 'Filtro',
         filtros: [
            {'key': 'Pacotes', 'value': 'Pacotes'},
            {'key': 'Cursos', 'value': 'Cursos'},
            {'key': 'Scripts', 'value': 'Scripts'}
         ],
         pesquisar: 'Pesquisar',
         pesquisarDesc: 'Buscar por nome',
         adicionar: 'Adicionar',
         addPacoteTitulo: 'Adicionar pacote',
         editPacoteTitulo: 'Editar pacote',
         addCursoTitulo: 'Adicionar curso',
         editCursoTitulo: 'Editar curso',
         addScriptTitulo: 'Adicionar script',
         editScriptTitulo: 'Editar script',
         infoPacoteTitulo: 'Informações do pacote',
         infoCursoTitulo: 'Informações do curso',
         infoScriptTitulo: 'Informações do script',
         campoTitulo: 'Título',
         campoValor: 'Valor',
         campoDescricao: 'Descrição',
         campoCategorias: 'Categorias',
         campoLinkDocumentacao: 'Link documentação',
         campoDono: 'Dono',
         campoOrdenacao: 'Ordenação',
         campoPromocao: 'Promoção',
         campoDesabilitado: 'Ocultar da loja',
         campoDestaque: 'Destaque',
         campoBanner: 'Banner do produto',
         nenhumBanner: 'Nenhum banner encontrado',
         campoConteudo: 'Conteúdo',
         campoCursos: 'Cursos do pacote',
         campoDescQtdCursos: 'curso(s)',
         nenhumCurso: 'Nenhum curso encontrado',
         campoScripts: 'Scripts do pacote',
         campoDescQtdScripts: 'script(s)',
         nenhumScript: 'Nenhum script encontrado',
         campoCodigo: 'Código',
         campoLinkDrive: 'Link do drive',
         campoLoginSvn: 'Login do SVN',
         campoSenhaSvn: 'Senha do SVN',
         campoLinkSvn: 'Link do SVN',
         campoVideo: 'Vídeo prévio',
         nenhumVideo: 'Nenhum vídeo encontrado',
         campoExclusivo: 'Exclusivo',
         campoOtimizado: 'Otimizado',
         campoTestado: 'Testado',
         campoSeguro: 'Sem vírus',
         campoImagens: 'Imagens',
         botaoSalvar: 'Salvar',
         botaoFechar: 'Fechar',
         validacaoTitulo: 'Insira um título!',
         validacaoCategoria: 'Insira uma categoria!',
         validacaoValor: 'Insira um valor!',
         validacaoTamanhoArquivo: 'Tamanho excedido',
         validacaoTamanhoArquivoDesc: 'Arquivo excede 10MB',
         validacaoLimiteImagens: 'Limite alcançado',
         validacaoLimiteImagensDesc: 'Limite máximo de imagens excedido (3).',
         validacaoCodigo: 'Insira um código!',
         pacoteSalvoSucesso: 'Pacote salvo!',
         deletarPacoteDesc: 'Deletar pacote?',
         deletarPacoteSucesso: 'Pacote deletado!',
         cursoSalvoSucesso: 'Curso salvo!',
         deletarCursoDesc: 'Deletar curso?',
         deletarCursoSucesso: 'Curso deletado!',
         scriptSalvoSucesso: 'Script salvo!',
         deletarScriptDesc: 'Deletar script?',
         deletarScriptSucesso: 'Script deletado!',
         botaoConfirmar: 'Confirmar',
         botaoCancelar: 'Cancelar'
      },
      pacote: {
         abrir: 'Abrir',
         editar: 'Editar',
         excluir: 'Excluir',
      },
      admModulo: {
         aulas: 'Aulas',
         addAula: 'Adicionar aula',
         editAula: 'Editar aula',
         addBloco: 'Adicionar bloco',
         editBloco: 'Editar bloco',
         infoAulaTitulo: 'Informações da aula',
         campoTitulo: 'Título',
         campoDescricao: 'Descrição',
         campoVideo: 'Vídeo',
         nenhumVideo: 'Nenhum vídeo encontrado',
         validacaoTitulo: 'Insira um título!',
         deletarBlocoDesc1: 'Deletar bloco?',
         deletarBlocoDesc2: 'As aulas do bloco serão excluídas',
         deletarBlocoSucesso: 'Bloco deletado!',
         deletarAulaDesc: 'Deletar aula?',
         deletarAulaSucesso: 'Aula deletada!',
         botaoSalvar: 'Salvar',
         botaoFechar: 'Fechar',
         botaoConfirmar: 'Confirmar',
         botaoCancelar: 'Cancelar'
      },
      admEstatisticas: {
         filtro: 'Filtro',
         filtros: [
            {'key': 'Dono', 'value': 'Dono'},
            {'key': 'Autor', 'value': 'Autor'},
            {'key': 'Categoria', 'value': 'Categoria'},
            {'key': 'Pacote', 'value': 'Pacote'},
            {'key': 'Script', 'value': 'Script'}
         ],
         pacote: 'Pacote',
         pacoteDesc: 'Todos os pacotes',
         script: 'Script',
         scriptDesc: 'Todos os scripts',
         dono: 'Dono',
         donoDesc: 'Todos os donos',
         categoria: 'Categoria',
         categoriaDesc: 'Todos as categorias',
         autor: 'Autor',
         autorDesc: 'Buscar por nome do autor',
         data: 'Data',
         semResultados: 'Sem resultados',
         vendasDiarias: 'Vendas diárias',
         maisVendidos: 'Mais vendidos'
      },
      admPedidos: {
         filtro: 'Filtro',
         filtros: [
            {'key': 'Dono', 'value': 'Dono'},
            {'key': 'Autor', 'value': 'Autor'},
            {'key': 'Categoria', 'value': 'Categoria'},
            {'key': 'Pacote', 'value': 'Pacote'},
            {'key': 'Script', 'value': 'Script'}
         ],
         pacote: 'Pacote',
         pacoteDesc: 'Todos os pacotes',
         script: 'Script',
         scriptDesc: 'Todos os scripts',
         dono: 'Dono',
         donoDesc: 'Todos os donos',
         categoria: 'Categoria',
         categoriaDesc: 'Todos as categorias',
         autor: 'Autor',
         autorDesc: 'Buscar por nome do autor',
         data: 'Data',
         semResultados: 'Sem resultados',
         semResultados2: 'Nenhum resultado encontrado',
         pacotes: 'Pacotes',
         pagamento: 'Pagamento',
         verPedidoTitulo: 'Pedido',
         infoPedidoTitulo: 'Informações do pedido',
         nenhumPacote: 'Nenhum pacote encontrado',
         campoNomeCliente: 'Nome do cliente',
         campoIdCliente: 'ID Cliente',
         campoValor: 'Valor total',
         campoPagamento: 'Forma de pagamento',
         campoTransacao: 'ID transação',
         campoDataCompra: 'Pedido realizado',
         campoMovimentacao: 'Última movimentação',
         campoUrlPagamento: 'Url pagamento',
         botaoFechar: 'Fechar',
         alterarStatus: 'Alterar status',
         alterarStatusDesc: 'Selecione um status',
         alterarStatusValidacao: 'Opção inválida!',
         alterarStatusSucesso: 'Status salvo!',
         botaoConfirmar: 'Confirmar',
         botaoCancelar: 'Cancelar',
         status: {
            'Aguardando pagamento': 'Aguardando pagamento',
            'Cancelado': 'Cancelado',
            'Finalizado': 'Finalizado'
         }
      },
      admUsuarios: {
         pesquisar: 'Pesquisar',
         pesquisarDesc: 'Buscar por nome, username, Whatsapp ou email',
         permissoes: 'Permissões',
         pacotes: 'Pacotes',
         jornada: 'Jornada',
         editar: 'Editar',
         excluir: 'Excluir',
         adicionar: 'Adicionar',
         addPacote: 'Adicionar pacote',
         semResultados: 'Nenhum resultado encontrado',
         botaoSalvar: 'Salvar',
         botaoVoltar: 'Voltar',
         botaoFechar: 'Fechar',
         jornadaGeral: 'Geral',
         jornadaAulas: 'Aulas',
         campoNome: 'Nome completo',
         campoCpfCnpj: 'CPF/CNPJ',
         campoAdministrador: 'Administrador',
         campoSuporte: 'Suporte',
         salvoSucesso: 'Usuário salvo!',
         deletarDesc: 'Deletar usuário?',
         deletarSucesso: 'Curso deletado!',
         botaoConfirmar: 'Confirmar',
         botaoCancelar: 'Cancelar',
         removerPacoteDesc: 'Deletar pacote?',
         removerPacoteSucesso: 'Pacote deletado!',
         addPacoteDesc: 'Adicionar pacote?',
         addPacoteSucesso: 'Pacote adicionado!'
      },
      admLogs: {
         filtro: 'Filtro',
         filtros: [
            {'key': 'Aula visualizada', 'value': 'Aula visualizada'},
            {'key': 'Compra', 'value': 'Compra'},
            {'key': 'Usuario', 'value': 'Usuario'}
         ],
         semResultados: 'Nenhum resultado encontrado',
         verMais: 'Ver mais'
      },
      admPerfil: {
         cursos: 'Cursos',
         pacotes: 'Pacotes',
         editar: 'Editar',
         campoNome: 'Nome completo',
         campoCpfCnpj: 'CPF/CNPJ',
         botaoSalvar: 'Salvar',
         botaoFechar: 'Fechar',
         salvarSucesso: 'Dados salvos!'
      }
   }
}
