<template>
   <li class="w-max-content text-white">
      <a class="p-0 limitador font-13" href="javascript:;" @click="pesquisar"><span>{{ categoria.value }}</span></a>
   </li>
</template>

<script>

import { mapState } from 'vuex'

export default {
   name: 'Categoria',
   props: ['categoria'],
   computed: {
		... mapState({
			pesquisa: state => state.pesquisa
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      pesquisar : function () {
         this.$store.dispatch('pesquisar', {'tag': this.categoria.key, 'valor': this.pesquisa.valor, 'filtro': this.pesquisa.filtro})
      }
   }
}

</script>

<style scoped>

.border-right {
   border-right: 1px solid #ebebeb;
}

.border-right::after {
   display: none;
}

.card::after {
   content: '';
   display: block;
   height: 100%;
   position: absolute;
   top: 0;
   width: 100%;
}

.card-text {
   text-shadow: 1px 1px 3px #000;
   color: #fff;
   z-index: 1;
}

.submenu li a.position-relative span {
   position: absolute;
   bottom: 8px;
   left: 8px;
   right: 8px;
	color: #fff;
	text-shadow: 1px 1px 1px #000;
   font-size: 16px !important;
}

.submenu li:first-child {
   line-height: 1.5 !important;
}

li:not(.not-dropdown) a {
   font-weight: 400 !important;
   /* text-transform: capitalize !important; */
   color: rgb(81, 90, 108) !important;
}

li {
   line-height: 2 !important;
}

.submenu li {
   line-height: 2.2 !important;
}

.submenu img {
	aspect-ratio: 1 / 1;
}

.submenu i {
   position: unset !important;
   top: unset !important;
   right: unset !important;
   transform: unset !important;
}

* {
   cursor: auto;
}

a span {
   cursor: pointer !important;
}

</style>