<template>
	<div id="app" class="admin" v-if="isRouteAdmin">
		<div class="body-loading" v-if="isCarregando == true">
			<div class="clock-loader"></div>
		</div>

		<div class="wrapper" v-if="isLogado">
			<!-- Menu Lateral -->
			<Lateral />

			<!-- Menu NavBar -->
			<NavBar />
			
			<!-- Corpo sistema -->
			<div class="page-wrapper">
				<div class="page-content pb-5">
					<h5 class="mb-3">{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/").replace("Adm", "") : '' }}</h5>
					
					<router-view />
				</div>

				<a href="javaScript:;" class="back-to-top bg-theme" @click="backToTop">
					<i class='fas fa-arrow-up'></i>
				</a>
			</div>

			<div class="overlay toggle-icon" @click="toggleMobileMenu"></div>
			
			<footer class="page-footer">
				<span class="ps-2 float-start d-none d-md-block">Copyright © 2024. Todos direitos reservados</span>
				<span class="pe-2 float-end">One Way Systems</span>
			</footer>
		</div>

		<Login v-else />
   </div>

	<div id="app" class="cliente" v-else>
		<div id="loading" v-if="isCarregando == true">
			<div class="loader"></div>
		</div>

		<a href="javascript:;" class="back-to-top bg-theme" @click="backToTop">
			<i class='fas fa-arrow-up'></i>
		</a>
		
		<Header v-show="$route.name != 'Produto_Divulgação'" />

		<div class="offcanvas__area">
			<div class="offcanvas__wrapper">
				<div class="offcanvas__close">
					<button class="offcanvas__close-btn mt-sm-2" id="offcanvas__close-btn" @click="fecharMenu">
						<i class="fal fa-times"></i>
					</button>
				</div>
				<div class="offcanvas__content">
					<div class="offcanvas__logo mb-15">
						<a href="javascript:;">
							<img src="@/assets/cliente/img/logo/logo-black.png" alt="logo">
						</a>
					</div>
					<div class="offcanvas__search mb-25">
						<form action="javascript:;">
							<input type="text" placeholder="Pesquisar..." v-model="pesquisa.valor" @keyup.enter="pesquisar">
							<button @click="pesquisar"><i class="far fa-search"></i></button>
						</form>
					</div>
					<div class="mobile-menu fix mean-container">
						<div class="mean-bar">
							<nav class="mean-nav">
								<ul>
									<li><router-link to="/termosCompras" class="weight-400">Termos de compras</router-link></li>
									<li><a href="javascript:;" @click="setRouteAdmin" class="weight-400">Área do cliente</a></li>
								</ul>
							</nav>
						</div>
					</div>
					<div class="offcanvas__action"></div>
				</div>
			</div>
		</div>
		<div class="body-overlay" @click="fecharMenu"></div>

		<router-view />

		<Footer />
   </div>
</template>

<script>

import $ from 'jquery'
import Vue from 'vue'
import router from '@/router'
import { mapState } from 'vuex'
import Login from '@/views/admin/Login.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import NavBar from '@/components/admin/MenuNavBar.vue'
import Lateral from '@/components/admin/MenuLateral.vue'

export default {
	name: 'App',
	data: function () {
		return {
			pesquisa: {'tag': 'Todos', 'valor': '', 'filtro': 'Mais recentes'}
		}
	},
	computed: {
		... mapState({
         isCarregando: state => state.isCarregando,
         isRouteAdmin: state => state.isRouteAdmin,
			dadosUsuario: state => state.dadosUsuario,
         isLogado: state => state.isLogado,
         tokenSessao: state => state.tokenSessao
		})
	},
	components: {
		Footer, Header, Login, Lateral, NavBar
	},
	methods: {
      pesquisar : function () {
         this.$store.dispatch('pesquisar', this.pesquisa)
      },
		fecharMenu : function () {
			$(".offcanvas__area").removeClass("opened");
			$(".body-overlay").removeClass("opened");
		},
		toggleMobileMenu : function () {
         $(".wrapper").hasClass("toggled") ? ($(".wrapper").removeClass("toggled"), $(".sidebar-wrapper").unbind("hover")) : ($(".wrapper").addClass("toggled"), $(".sidebar-wrapper").hover(function() {
            $(".wrapper").addClass("sidebar-hovered")
         }, function() {
            $(".wrapper").removeClass("sidebar-hovered")
         }))
      },
		backToTop : function () {
			if (this.isRouteAdmin) {
				$('.page-content').animate({scrollTop:0}, '300');
			} else {
				window.scrollTo(0, 0)
			}
		},
		setRouteAdmin : function () {
         this.$store.dispatch('isRouteAdmin', true)
         router.push('/admInicio')
      },
	},
	mounted() {
		this.$store.dispatch('getData').finally(() => {
			this.$store.dispatch('login', {'redirect': true})
		})

		this.$axios.interceptors.request.use((config) => {
			if (this.tokenSessao != null && this.dadosUsuario != null) {
				config.headers.Authorization = 'Bearer '+ this.tokenSessao +'@@@'+ this.dadosUsuario.idDiscord;
			}

			return config;
		});

		Vue.nextTick(function() {
         $(document).scroll(function() {
            if ($(document).scrollTop() > 300) {
               $('.back-to-top').fadeIn();
            } else {
               $('.back-to-top').fadeOut();
            }
         });
		}.bind(this));
	}
}

</script>